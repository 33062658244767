import { type BaseSyntheticEvent, useEffect, useState, useRef } from 'react'

export function smoothScroll(event: BaseSyntheticEvent, offset?: number) {
  const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style
  if (isSmoothScrollSupported) {
    event.preventDefault()
    let { target } = event
    while (target && !target.hash) target = target.parentElement

    let hash
    hash = decodeURIComponent(target?.hash)

    if (hash === 'undefined') {
      hash = `#${event.target.getAttribute('id')}`
    }

    const element = document.getElementById(hash?.substring(1))

    if (element) {
      const elementPosition = element.offsetTop
      if (!offset) {
        element.scrollIntoView({ behavior: 'smooth' })
      } else {
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        })
      }

      window.history.replaceState(null, null, hash)
    }
  }
}

export function scrollSpy(id) {
  useEffect(() => {
    // see anchor with link in rich-text.tsx
    // ff missing feature with :has https://caniuse.com/css-has
    // const scrollables = document.querySelectorAll('div.group:has(h2), div.group:has(h3)')
    const scrollables = document.querySelectorAll('div.group')
    scrollables.forEach((scrollable) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              document.querySelectorAll(`#${id} a`).forEach((el) => {
                el.getAttribute('href') === `#${entry.target.id}`
                  ? el.classList?.add('active')
                  : el.classList?.remove('active')
              })
              // not sure why this isn't working
              // menuItem.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
            }
          })
        },
        {
          root: null,
          rootMargin: '-80px 0px -85% 0px',
          threshold: 0.01,
        }
      )
      observer.observe(scrollable)
    })
  }, [])
}

export function downloadBlob(content, type, filename) {
  const blob = new Blob([content], { type })
  const link = document.createElement('a')

  link.download = filename
  link.href = window.URL.createObjectURL(blob)
  link.dataset.downloadurl = [type, link.download, link.href].join(':')

  link.dispatchEvent(
    new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
  )
  link.remove()
}

// this is computationally expensive!
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

export function selectElementText(elId: string) {
  const el = document.getElementById(elId)
  const sel = window.getSelection()
  if (sel) sel.removeAllRanges()
  if (el) {
    const range = window.document.createRange()
    if (range) {
      range.selectNodeContents(el)
      if (sel) sel.addRange(range)
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export function useHasScrolled(distance = '10px') {
  const topRef = useRef(null)
  const [hasScrolled, setHasScrolled] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries
        setHasScrolled(!entry.isIntersecting)
      },
      { rootMargin: distance }
    )

    observer.observe(topRef.current)
  }, [])
  return [topRef, hasScrolled]
}

export function extractDataAttributes(props: { [key: string]: any }): { [key: `data-${string}`]: any } {
  const dataAttributes: { [key: string]: any } = {}

  Object.entries(props).forEach(([key, value]) => {
    if (key.startsWith('data-')) {
      dataAttributes[key] = value
    }
  })
  return dataAttributes
}
