export function slugify(str) {
  if (typeof str !== 'string') return 'undef'

  return str
    .toLowerCase() // Convert the string to lowercase letters
    .normalize('NFKD') // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim() // Remove whitespace from both sides of a string
    .replace(/^[a-z]\.\s/g, '') // Remove list form items for terms.  Eg A. Bit of a hack
    .replace(/[']/g, '') // Remove certain non-word chars
    .replace(/[\s\W-_]+/g, '-') // Replace all non-word chars with -
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
}

export const cx = (...classNames: Array<string | boolean | undefined | null>) =>
  classNames.filter((cn) => !!cn).join(' ')

export const moneyFormat = (amount: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  })

  return formatter.format(amount || 0)
}

export const dateFormat = (
  dateString: string,
  locale: string,
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Partial<Record<string, string>>
) => {
  const date = new Date(dateString)
  date.setUTCHours(12) // add half day so that it doesn't tz round to the previous day
  return date.toLocaleDateString(locale, options)
}

export const dateTimeFormat = (
  dateString: string,
  locale: string,
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // timeZone: 'America/New_York',
    timeZoneName: 'short',
  } as Partial<Record<string, string>>
) => {
  const date = new Date(dateString)
  return date.toLocaleTimeString(locale, options)
}

export const dateDiff = (dateString: string, locale) => {
  const DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
  ]

  const date = Number(new Date(dateString))
  let duration = (date - Number(new Date())) / 1000

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      const formatter = new Intl.RelativeTimeFormat(locale, {
        numeric: 'auto',
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

export const emailRegex = '^[^\\s@]{1,64}@[^\\s@]{1,64}\\.[^\\s@]{1,64}$'

export const upperCaseLetters = (string) => string?.replace(/[a-z]/g, '')?.replace(/\s/g, '')

export const copyHtmlToClipBoard = (str) => {
  const plain = str
    .replace(/<\/p>/gi, '\n\n')
    .replace(/<\/ul>/gi, '\n\n')
    .replace(/<li>/gi, ' - ')
    .replace(/<\/li>/gi, '\n')
    .replace(/&nbsp;/gi, ' ')
    .replace(/href="\//gi, 'href="https://bitwarden.com/')
    .replace(/<a[^>]+href="(.*?)"[^>]*>([^<]+)<\/a>/gi, '[$2]($1)')
    .replace(/<[^>]*>?/gm, '')

  const html = str.replace(/href="\//gi, 'href="https://bitwarden.com/')

  const copyRichText = () => {
    navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([html], { type: 'text/html' }),
        'text/plain': new Blob([plain], { type: 'text/plain' }),
      }),
    ])
  }

  /**
   * This returns a boolean and is meant to catch browsers that don't
   * support the `execCommand('copy')` function
   */
  const execCommandAvailable = document.execCommand('copy')

  const copyRichTextFallback = () => {
    function listener(e) {
      e.clipboardData.setData('text/html', html)
      e.clipboardData.setData('text/plain', plain)
      e.preventDefault()
    }
    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)
  }

  const copyPlainText = () => {
    navigator.clipboard.writeText(plain)
  }

  window.ClipboardItem ? copyRichText() : execCommandAvailable ? copyRichTextFallback() : copyPlainText()
}
